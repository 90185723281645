
html {
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
}

body {
    font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
    margin: 0;
}

@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('fonts/glyphicons-halflings-regular.eot');
    src: 
        url('fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), 
        url('fonts/glyphicons-halflings-regular.woff2') format('woff2'),
        url('fonts/glyphicons-halflings-regular.woff') format('woff'), 
        url('fonts/glyphicons-halflings-regular.ttf') format('truetype'), 
        url('fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

li.indie-giving-list-content:before {
    content: '\e013';
    font-family: 'Glyphicons Halflings', serif;
    font-size: 12px;
    float: left;
    color: #2F5C6A;
    margin-right: 10px;
    position: relative;
}

li.indie-giving-list-content {
    color: #2F5C6A;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.slick-list{
    height: 288px;
}